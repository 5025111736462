<!--
 * @Author: Neko
 * @Date: 2021-01-06 17:09:34
 * @LastEditTime: 2021-01-13 16:50:05
 * @LastEditors: Neko
-->
<template>
  <div class="announcement__container">
    <div class="announcement__main">
      <div class="announcement__header">
        <span class="title">{{ $t('account.notice') }}</span>
      </div>

      <ul class="announcement__body">
        <li v-for="item in announcementList" :key="item.id" class="item">
          <a class="link" href="javascript:void(0)">
            <h3 class="title">{{ item.title }}</h3>

            <p class="content">
              {{ item.content }}
            </p>

            <p class="time">
              {{ item.createTime }}
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive, toRef, toRefs } from '@vue/composition-api'
import useChangeBackground from '@/composition/useChangeBackground.js'

import { useRequest } from '@/utils/request'

export default {
  name: 'AnnouncementList',

  setup(_, { root }) {
    const route = root.$route

    useChangeBackground('#f6f6f6')

    const data = reactive({
      announcementList: [],
      domainPrefix: route.query.domain_prefix
    })

    useRequest('announcement/getAnnouncementList', {
      data: {
        domainPrefix: data.domainPrefix
      },

      initialData: toRef(data, 'announcementList')
    })

    return {
      ...toRefs(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.announcement__main {
  width: 1200px;
  max-width: 1200px;
  margin: 23px auto 37px;
  background: #fff;
}

.announcement__header {
  padding: 0 31px;
  border-bottom: 1px solid #E2E2E2;

  .title {
    display: inline-block;
    width: 80px;
    font-size: 16px;
    padding: 14px 0 7px;
    border-bottom: 3px solid #0049AC;
    line-height: 24px;
    font-weight: bolder;
    text-align: center;
    letter-spacing: 2px;
    color: #0049AC;
  }
}

.announcement__body {
  padding: 0 30px 43px;
  margin: 0;

  .link {
    display: block;
  }

  .item {
    padding: 30px 0 20px;
    border-bottom: 1px solid #E2E2E2;
    list-style: none;

    .title {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #222222;
    }

    .content {
      @include line-clamp(2);
      margin: 23px 0;
      font-size: 14px;
      line-height: 22px;
      color: #999999;
    }

    .time {
      margin: 0;
      font-size: 12px;
      line-height: 22px;
      color: #222222;
    }
  }
}
</style>
