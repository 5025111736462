/*
 * @Author: Neko
 * @Date: 2021-01-06 16:27:32
 * @LastEditTime: 2021-01-06 17:50:54
 * @Description: 使某段代码 onMounted 时运行，并可以在 beforeUnmount 时指定清除函数
 * @LastEditors: Neko
 */

import { onBeforeUnmount, onMounted } from '@vue/composition-api'

/**
 * 传入函数，这个函数将在 onMounted 时运行，并且在销毁前会调用该函数的返回值。
 * @param {Function} fn 传入一个函数，函数内为 onMounted 时需要调用的代码，并指定返回一个函数，该函数将会在 beforeUnmount 时调用。
 */
export default function(fn = function() {}) {
  const fnObj = {
    value: fn
  }

  onMounted(() => {
    fnObj.value = fnObj.value()
  })

  onBeforeUnmount(() => {
    fnObj.value && fnObj.value()
  })
}
