/*
 * @Author: Neko
 * @Date: 2021-01-07 09:49:25
 * @LastEditTime: 2021-01-07 09:54:35
 * @LastEditors: Neko
 */

import useCodeInLifeCycle from './useCodeInLifeCycle'

/**
 * 更改背景色颜色，并且在销毁当前组件时，返回之前的背景。
 * TODO: 可以更改为更泛化的函数
 * @param {string} color 需要在生命周期内更改的背景色
 */
export default function(color) {
  useCodeInLifeCycle(() => {
    const backgroundColor = getComputedStyle(document.body).getPropertyValue('background-color')

    document.body.style.backgroundColor = color

    return () => {
      document.body.style.backgroundColor = backgroundColor
    }
  })
}

